// Fonts
@font-face {
  font-family: 'Axiforma';
  src: url('/font/Axiforma-Light.eot');
  src: url('/font/Axiforma-Light.eot?#iefix') format('embedded-opentype'),
       url('/font/Axiforma-Light.woff2') format('woff2'),
       url('/font/Axiforma-Light.woff') format('woff'),
       url('/font/Axiforma-Light.ttf') format('truetype'),
       url('/font/Axiforma-Light.svg#Axiforma-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/font/Axiforma-Regular.eot');
  src: url('/font/Axiforma-Regular.eot?#iefix') format('embedded-opentype'),
       url('/font/Axiforma-Regular.woff2') format('woff2'),
       url('/font/Axiforma-Regular.woff') format('woff'),
       url('/font/Axiforma-Regular.ttf') format('truetype'),
       url('/font/Axiforma-Regular.svg#Axiforma-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/font/Axiforma-Medium.eot');
  src: url('/font/Axiforma-Medium.eot?#iefix') format('embedded-opentype'),
       url('/font/Axiforma-Medium.woff2') format('woff2'),
       url('/font/Axiforma-Medium.woff') format('woff'),
       url('/font/Axiforma-Medium.ttf') format('truetype'),
       url('/font/Axiforma-Medium.svg#Axiforma-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Axiforma';
  src: url('/font/Axiforma-Bold.eot');
  src: url('/font/Axiforma-Bold.eot?#iefix') format('embedded-opentype'),
       url('/font/Axiforma-Bold.woff2') format('woff2'),
       url('/font/Axiforma-Bold.woff') format('woff'),
       url('/font/Axiforma-Bold.ttf') format('truetype'),
       url('/font/Axiforma-Bold.svg#Axiforma-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

// Body
$body-bg: #fbfcff;
$body-color: rgba(23, 32, 97, 0.7);

// Colors 
$primary-color: #222d74;
$accent-primary-color: #172061;
$secondary-color: #377dff;
$bg-color: #f2f7ff;
$border-color: #dee7ff;
$accent-pink: #ffe3de;
$accent-dark-pink: #e226c4;
$accent-blue: #e5f1ff;
$accent-dark-blue: #377dff;
$accent-green: #e0fff4;
$accent-dark-green: #43b6cf;
$accent-purple: #7b61ff;
$light-gray: #f6f7fc;
$red: #f70041;
$green: #2DCA8C;
$red-bg: rgba(247, 0, 65, 0.1);
$light-blue: #1F8ae0;
$dark-blue: #254ab0;
$yellow: #ffe248;
$black: #171A22;

$new-primary-color: #254ab0;

// Navbar Height
$navbar-padding-y: 11px;

// Typography
$font-family-sans-serif: 'Axiforma', sans-serif;
$font-size-base: 0.9rem;
$font-weight-base: 300;
$line-height-base: 1.6;
$font-h-size: 45px;
$line-height-h: 70px;

// Elements
$padding: 100px;
$border-radius: 6px;

// Container Sizes
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1200px
);
