// Fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

$fa-font-path: '../webfonts';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';

img {
    max-width: 100%;
    height: auto;
}

[x-cloak] { display: none !important; }

header .search-box,
#sidebar,
#sidebarCollapse {
    will-change: transform;
    transform: translateZ(0);
}

html,
body:not(.pricing),
footer,
main,
#profile-reviews,
#profile-overview,
.profile-navigation,
.profile-bio {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: auto;
    position: relative;
}

#sidebarCollapse {
    transition: none;
}

// Headings
h1,
h2 {
    color: $accent-primary-color;
    font-size: $font-h-size;
    line-height: $line-height-h;
    font-weight: bold;
}

.h2-small {
    font-size: 24px;
    line-height: 1.5;
}

h3 {
    color: $accent-primary-color;
    font-size: 18px;
    line-height: 32px;
    font-weight: bold;
}

h4 {
    font-size: 14px;
    font-weight: bold;
    color: $primary-color;
    line-height: 1.75;
}

.subtitle {
    color: $secondary-color;
    font-weight: bold;
}

.btn-check:focus + .btn, .btn:focus,
.form-check-input:focus {
    box-shadow: none;
}

// Header
header {
    background-color: $bg-color;

    .navbar {
        z-index: 9;

        .navbar-nav {

            .nav-item {
                &.active {
                    .nav-link {
                        color: $secondary-color;
                    }
                }
            }

            img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }

            .nav-link {
                color: $primary-color;
                font-weight: normal;
                padding-left: 20px;
                padding-right: 20px;
                transition: none;

                &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
}

#sidebar {
    display: none;
    min-width: 230px;
    max-width: 230px;
    width: 230px;
    height: 100vh;
    position: fixed;
    background: white;
    color: $primary-color;
    //transition: all 0.3s;
    transition: none;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: auto;
    
    &.active {
        margin-left: -230px;
    }

    .sidebar-header {
        padding: 20px;
    }

    ul {

        li {
            padding: 14px 20px;
            position: relative;

            a {
                color: $primary-color;
                font-size: 14px;
                line-height: 1;
                font-weight: 400;
                text-decoration: none;
                vertical-align: middle;

                &.btn {
                    line-height: 1.6;
                }

            }

            &.active,
            &:hover {
            
                &:before {
                    content: "";
                    width: 6px;
                    height: 100%;
                    display: block;
                    background-color: $light-blue;
                    border-radius: 0px 5px 5px 0px;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                
                > a {
                    color: $light-blue;
                }
            }
        }
    }
}

// Footer
footer {
    background-color: $bg-color;
    padding: 50px 0;
    color: $primary-color;

    h3 {
        font-size: 14px;
        line-height: 1.75;

        &:after {
            content: "";
            width: 83px;
            height: 2px;
            display: block;
            background-color: $red;
        }
    }

    a {
        color: $primary-color;
        text-decoration: none;

        &:hover {
            color: $secondary-color;
        }
    }

    .social {

        a { 
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background: linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
            text-align: center;

            i {
                font-size: 18px;
                color: white;
                padding: 7.5px 0;
            }
        }
    }
}

// Elements
.form-group label,
.form-check label {
    font-weight: bold;
    color: $accent-primary-color;
}

.form-check label {
    font-size: 12px;
    font-weight: 300;
}

.input-group-text,
.form-control,
.filter .dropdown-toggle {
    padding: 10px;
    font-size: 14px;
    line-height: 1;
    color: $primary-color;
    background-color: white;
    border: 0.3px solid $border-color;
    border-radius: $border-radius;
    height: 40px;
    font-weight: 300;
    line-height: 20px;
}

.filter .dropdown-toggle {
    line-height: 1;
    color: $body-color;
    text-align: left;
    position: relative;
    z-index: 99;
    border: 0.3px solid $border-color !important;
}

.form-control {

    &.transparent {
        color: white;
        background-color: rgba(255, 255, 255, 0.07);
        border-color: rgba(222, 231, 255, 0.5);

        &::placeholder {
            color: white;
        }

        &:focus {
            background-color: rgba(255, 255, 255, 0.07);
        }
    }

    &[readonly] {
        background-color: white;
    }
}

.form-group {
    select {
        background: url('/images/icons/chevron-down.svg') 98% center no-repeat !important;
    }
}

.form-group,
.form-check {
     margin-bottom: 20px;
}

.basic-addon,
#basic-addon-review,
#basic-addon-find-me {
    padding-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 40px;
}

.form-control:focus {
    color: inherit;
    background-color: white;
    border-color: $border-color !important;
    outline: 0;
    box-shadow: none;
}

.form-control::placeholder {
    color: $body-color;
    opacity: 1;
}

.dropdown-toggle:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f107" !important;
    border: none !important;
    color: $primary-color;
    position: absolute;
    right: 10px;
}

.filter .dropdown-menu {
    font-size: inherit;
    background-color: $border-color;
    border: 1px solid $border-color;
    border-radius: 0;
    padding: 15px 20px 10px;
    width: 100%;
    top: -6px !important;
    z-index: 9;

    .dropdown-item {
        margin: 0;

        label {
            font-size: 14px;
            font-weight: 300;
            color: $accent-primary-color;
        }

        &:hover,
        &:focus {
            background-color: transparent;
        }
    }
}

.button {
    background-color: $primary-color;
    border-radius: $border-radius;
    color: white !important;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        background: $accent-primary-color;
        color: white;
    }

    &.button-outline {
        background-color: white;
        border: 1px solid $primary-color;
        color: $primary-color !important;

        &:hover {
            background-color: $primary-color;
            color: white !important;
        }

        &.light {
            background-color: transparent;
            border-color: white;
            color: white !important;

            &:hover {
                background-color: white !important;
                border: 1px solid $secondary-color;
                color: $secondary-color !important;
            }
        }
    }

    &.button-inverted {
        background-color: white;
        color: $primary-color !important;

        &:hover {
            background-color: $primary-color;
            color: white !important;
        }
    }

    &.button-claim {
        background-color: $red;
    }

    &:hover {
        background-color: $accent-primary-color;
    }

}

.menu-button {

    .nav-link {

        border: 1px solid $primary-color;
        border-radius: $border-radius;

        &:hover {
            border-color: $light-blue;
        }
    }

    &.active {

        .nav-link {
            border-color: $light-blue;
        }
    }
}

.filters-collapse {
    margin-top: 25px;
    text-decoration: none;
    font-weight: bold;
    padding-top: 10px;
    color: $primary-color;

    &:hover {
        color: $primary-color;
    }

    &.dropdown-toggle {

        &:after {
            right: 20px;
        }
    }
}

.search {

    .form-control {
        border-radius: $border-radius;
        border-left-width: 0;
    }

    .input-group-text {
        border-right-width: 0;
    }
}

.search-results {
    position: absolute;
    width: 100%;
    max-height: 333px;
    z-index: 2;
    left: 0;
    top: 18px;
    text-align: left;
    overflow-y: scroll;
    scrollbar-width: thin;

    .search-result {
        padding: 10px;
        border-bottom: 1px solid $border-color;
        background-color: white;
        display: block;
        color: $primary-color;
        text-decoration: none;

        em {
            font-weight: 500;
            font-style: normal;
            color: $secondary-color;
        }

        .city-state {
            display: block;
            font-size: 12px;
            line-height: 1;
        }

        img {
            height: 50px;
            border-radius: 50%;
            background: $light-gray;
            margin-right: 15px;
            width: auto;
        }

        &.number-results {
            font-size: 12px;
            color: $primary-color;
            padding: 26px 10px 5px;

            span {
                font-weight: 500;
                color: $secondary-color;
            }
        }
    }

    &.specialties {

        .search-result {

            &.top-level-specialty {
                background-color: $border-color;
                font-weight: 700;
            }

            &.sub-level-specialty {
                font-style: italic;
            }
        }
    }
}

.pagination {

    .page-item {

        .page-link {
            border-color: $border-color !important;
            color: $primary-color;
        }

        &.active {
            .page-link {
                background-color: $primary-color !important;
                border-color: $primary-color !important;
                font-weight: 400;
                color: white;
            }
        }
    }
}

.search-by-location {

    .input-group {
        z-index: 99;
    }

    .form-control {
        border-radius: 0;
        border-right-width: 0;
    }

    .btn-geo {
        background-color: white;
        height: 40px;
        border: 0.3px solid #dee7ff;
        border-radius: 0 $border-radius $border-radius 0;
        border-left-width: 0;

        svg {

            path {
                fill: $body-color;
            }
        }
    }
}

.search-dropdown {
    .search-results {
        top: 34px;

        &.section-border {
        border-radius: 0;
        }

        .search-result {

            &:first-of-type {
                padding-top: 16px;
            }
        }
    }
}

.search-box {
    border-radius: 50px;
    box-shadow: 0px 16px 40px rgba(55, 125, 255, 0.25);

    .input-group-text,
    .form-control {
        border-right-width: 1px;
        background-color: white;
    }

    .form-control {
        border-radius: 0;
        border-left-width: 0;
        z-index: 5 !important;
        border-top-right-radius: 50px !important;
        border-bottom-right-radius: 50px !important;
    }

    .input-group {
        position: unset;
    }

    .input-group-prepend {
        z-index: 5;
    }

    .basic-addon,
    #basic-addon-review,
    #basic-addon-find-me {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
    }

    .button {
        border-radius: 0 50px 50px 0;
        border-width: 1px 1px 1px 0;
        border-style: solid;
        border-color: $border-color;
        z-index: 3;
    }
}

.header-search {

    .search-box {
        box-shadow: none;
        margin-left: 20px;
        width: 60px;

        .input-group-text {
            padding: 5px;
            background-color: $bg-color;
            border-color: $bg-color;
        }

        .form-control,
        .search-results {
            display: none;
        }

        &:hover {
            width: 275px;
            transition: linear all 0.15s;

            .form-control,
            .search-results {
                display: block;
            }

            .input-group-text {
                background-color: white;
                border-color: $border-color;
            }
        }
    }
}

.img-top-radius {
    border-radius: $border-radius $border-radius 0 0;
}

.section-spacing {
    padding-top: $padding;
    padding-bottom: $padding;
}

.spacing-top {
    margin-top: $padding;
}

.spacing-bottom {
    margin-bottom: $padding;
}

.section-border {
    border: 1px solid $border-color;
    border-radius: $border-radius;
}

.section-padding {
    padding: 30px 25px;
}

.section-shadow {
    box-shadow: 0px 16px 40px rgba(55, 125, 255, 0.15);
}

// Cover
#cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 700px;

    #cover-overlay-top {
        background: linear-gradient(180deg, #DEE7FF 0%, rgba(222, 231, 255, 0) 100%);
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 84.74%;
    }

    #cover-overlay-bottom {
        background: linear-gradient(0deg, #DEE7FF 0%, rgba(222, 231, 255, 0) 100%);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 35.74%;
    }

    p {
        font-size: 16px;
        font-weight: normal;
    }

    #home-search {
        width: 860px;
        text-align: center;
        z-index: 1;
    
        p {
            color: $primary-color;
            line-height: 1;
        }
    
        h1 {
            font-size: 36px;
            color: $primary-color;
        }

        .search-box {
            box-shadow: 0px 16px 40px rgba(55, 125, 255, 0.4);
        }
    
    }
}

// Features
#features {

    svg {
        height: 40px;

        path {
            fill: $secondary-color !important;
        }
    }

    h3 {
        font-weight: 500;
        line-height: 1.5;
    }
}

// How it Works
#how-it-works {

    .list-group-item {
        border: none;
        border-radius: 0;
        border-image: url('/images/line.svg') 0 0 0 10;
        border-image-width: 1px;
        position: relative;
        padding: 0.5rem 2rem;
        margin-left: 20px;
        background-color: transparent;

        &:last-of-type {
            border-image-width: 0;
        }

        .icon {
            position: absolute;
            top: 0;
            left: -20px;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            &.search {
                background-color: $accent-pink;
            }

            &.check-reviews {
                background-color: $accent-blue;
            }

            &.subscribe {
                background-color: $accent-green;
            }
        }
    }

    #graphic {
        max-width: 521px;
        background: linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
        border-radius: 50%;

        #graphic-rating {
            position: absolute;
            top: 19.96%;
            left: 11.32%;
            padding: 13px 8px 9px;
            background-color: white;
            box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
            border-radius: $border-radius;

            span {
                font-weight: bold;
                color: $primary-color;
                line-height: 1;
                font-size: 18px;
                margin-right: 8px;
            }

            img {
                margin-top: -3px;
            }
        }

        #graphic-block {
            position: absolute;
            bottom: 15%;
            right: -5%;
            padding: 13px 23px;
            background-color: white;
            box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            text-align: center;

            #graphic-name {
                color: $primary-color;
                font-weight: bold;
                display: block;
            }

        }
    }
}

// Stats
#stats {
    padding-bottom: $padding;

    .stats-box {
        padding: 29.5px 36px;
        border-width: 0 1px 1px 1px;
        border-radius: 0 0 $border-radius $border-radius;
        border-style: solid;
        border-color: $border-color;
        position: relative;
        margin-top: 75px;

        &:before {
            content: "";
            width: calc(100% + 1.6px);
            height: 38px;
            border-radius: $border-radius $border-radius 0px 0px;
            border: 1px solid $border-color;
            display: block;
            position: absolute;
            left: -0.8px;
            top: -38px;
        }

        &.no-reviews {
            background: url('/images/stats-no-reviews-bg.svg') center center no-repeat;

            &:before {
                background-color: $light-blue;
                border-color: $light-blue;
            }
        }

        &.emails-sent {
            background: url('/images/stats-emails-sent.svg') center center no-repeat;

            &:before {
                background-color: $dark-blue;
                border-color: $dark-blue;
            }
        }

        &.providers-tracked {
            background: url('/images/stats-providers-tracked.svg') center center no-repeat;

            &:before {
                background: linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
                border-color: $light-blue;
            }
        }

        &.hospitals-tracked {
            background: url('/images/stats-hospitals-tracked.svg') center center no-repeat;

            &:before {
                background-color: $light-blue;
                border-color: $light-blue;
            }
        }
    }
}

// Top Providers
#top-providers {
    background-color: rgba(55, 125, 255, 0.1);

    .view-all-doctors {
        text-align: right;
    }
}

.profile-loading {
    height: 4100px !important;
}

.doctors-block {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    width: 24%;
    margin-right: 1.33%;
    margin-bottom: 16px;
    text-decoration: none;

    &:nth-of-type(4n) {
        margin-right: 0;
    }

    .doctors-overview {

        .doctors-image {
            border-right: 1px solid $border-color;

            div {
                overflow: hidden;
                border-top-left-radius: $border-radius;

                img {
                    transform: scale(1.1);
                }
            }
        }

        .doctors-name {
            padding: 10px;
            width: calc(100% - 81px);

            h3 {
                line-height: 1;
                color: $primary-color;
            }

            p {
                font-size: 12px;
                color: $primary-color;
                font-weight: normal;
            }
        }
    }

    .profile-stats-box {
        border-radius: 0;
        width: 100% !important;
        color: white;
        background: $primary-color;
        padding: 10px 15px 8px;

        .profile-rating,
        .profile-total-reviews {
            margin: 0 ;
        }
    }

    .doctors-address {
        padding: 20px 20px 0;
    }
}

// Blog
.blog-wrap {

    .blog-block {
        border: 1px solid $border-color;
        border-radius: $border-radius;
        background-color: white;
        width: 32%;
        margin-right: 2%;
        margin-bottom: 1.2rem;
        position: relative;
        padding-bottom: 36px;

        .blog-image {
            height: 220px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            border-radius: $border-radius $border-radius 0 0;
            filter: drop-shadow(0px 16px 40px rgba(0, 0, 0, 0.15));
        }

        .article-desc {
            padding: 25px 20px;

            a {
                text-decoration: none;
            }

            .button {
                position: absolute;
                left: 20px;
                bottom: 20px;
            }

            date {
                font-size: 12px;
                display: inline-block;
                padding: 3px 12px 2px;
                line-height: 1;
                background-color: $light-gray;
                border-radius: 20px;
                margin-bottom: 1rem;
                color: $accent-primary-color;
                font-weight: 400;
            }
        }
        
        &:nth-of-type(1):not(.related-post) {
            width: 100%;
            margin-right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 0;

            div:nth-of-type(1) {
                width: 55%;
                border-radius: $border-radius 0 0 $border-radius;
                filter: none;
            }

            div:nth-of-type(2) {
                width: 45%;
                position: relative;
                padding: 40px 40px 76px;

                h3 {
                    font-size: 28px;
                }

                .button {
                    left: 40px;
                    bottom: 20px;
                }
            }

            .blog-image {
                height: 100%;
            }
        }

        &:nth-of-type(3n+1):not(.related-post) {
            margin-right: 0;
        }

        &.related-post:nth-of-type(3n) {
            margin-right: 0;
        }

    }
}

#blog-single {

    .blog-single-author {
        color: $primary-color;

        .blog-single-author-name {
            font-weight: 700;
            font-size: 16px;
        }

        .blog-single-author-subtitle {
            font-weight: 400;
        }
    }

    #blog-single-header {

        h1 {
            font-size: 36px;
            line-height: 1.5;
        }

        .blog-single-excerpt {

            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 1.5;
                font-style: italic;
                color: $primary-color;
            }
        }

    }

    #blog-single-content {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
        color: $primary-color;

        a {
            color: $primary-color;
        }

        .blog-single-content-main {

            h2 {
                font-size: 24px;
                line-height: 1.5;
            }

            img {
                width: 100%;
                height: 337px;
                object-fit: cover;
            }

        }

        .blog-single-content-date {
            font-size: 12px;
            font-weight: 400;

            date {
                padding: 5px 8px 3px 8px;
                border-radius: 25px;
                background-color: #f6f7fc;
            }
        }

        #blog-single-author-box {

            .col-md-9 {
                overflow: hidden;
            }

            .blog-single-author-box-image {
                width: 25%;
                background-color: $body-bg;
                margin-bottom: -99999px;
                padding-bottom: 99999px;
                margin-top: -99999px;
                padding-top: 99999px;
            }

            .blog-single-author-box-description {
                width: 70%;

                h3 {
                    font-size: 16px;
                    line-height: 1.5;
                }

                .blog-single-author-description {
                    font-size: 14px;
                    font-weight: 300;
                }
            }
        }
    }

}

// Call to Action
.call-to-action {
    min-height: 355px;
    background: url('/images/cta-bg.svg') center center no-repeat, linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
    border-radius: $border-radius;
    margin-bottom: $padding;

    div {
        max-width: 750px;
    }

    h2 {
        font-size: 36px;
        line-height: 1.3;
        color: white;
    }
}

// Newsletter Call to Action
.newsletter-call-to-action {
    border-radius: $border-radius;
    padding: 1rem;
    margin-bottom: 48px;
    min-height: 400px;
    background: url('/images/cta-bg-mobile.png') center center no-repeat, linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
    background-size: cover;

    div {
        max-width: 750px;
    }

    h2 {
        font-size: 24px;
        line-height: 1.3;
        color: white;
    }
}

//Claim your profile section
#claim-section {

    min-height: 230px;
    background: $light-blue url('/images/claim-profile-bg.svg') no-repeat;
    background-size: 130%;
    background-position: -48px -30px;

    h3 {
        color: white;
        font-size: 26px;
        font-weight: normal;
    }

    .btn {
        background: $green;

        &:hover {
            background-color: $primary-color;
        }
    }

    #claim-section-image {
        background: $light-blue url('/images/claim-section-image.svg') no-repeat;
        background-size: cover;
        min-height: 230px;

        img {
            bottom: 10px;
            right: 10px;
        }
    }
}

// Contact Page
#contact {
    .section-border {
        background: #ffffff url('/images/contact-bg.png') center center no-repeat;
        background-size: cover;
    }

    form {
        max-width: 540px;
    }
}

#contact-map {
    margin-bottom: $padding;
}

// Pricing Page
.pricing {

    main {
        overflow-y: unset;
        overflow-x: unset;
    }

    .pricing-page-subtitle {
        font-size: 24px;
        color: $red;
        font-weight: 700;
    }

    h2 {
        font-size: 36px;
        color: $black;
    }

    .button {
        background-color: $light-blue;
    }

    .pricing-plans {
        flex-wrap: wrap;

        [class*='col-'] {
            display: flex;
            flex-direction: column;
        }

        .pricing-box {
            height: 100%;
            color: $black;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            background-color: white;
            border-radius: 16px;
            box-shadow: 5px 5px 25px rgba(137, 141, 154, 0.3);

            .pricing-plan-label {
                font-size: 22px;
                font-weight: 700;
                color: $black;
                line-height: 1.25;
            }

            .pricing-plan-price {
                font-size: 40px;
                font-weight: 700;
                line-height: 1;
                color: $black;
            }

            .pricing-plan-price-decimal,
            .pricing-plan-price-period,
            .pricing-plan-price-card {
                font-size: 16px;
                font-weight: 700;
                line-height: 1;
                color: $black;
            }

            .pricing-plan-price-card {
                font-weight: 400;
                color: $light-blue;
            }

            .pricing-top-level {
                list-style: none;
                padding-left: 0;
                line-height: 2;
                
                li {
                    
                    &:before {
                        content: '\f00c';
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        color: $green;
                        width: 14px;
                        height: 14px;
                        font-size: 14px;
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 1;
                        margin-right: 5px;
                        margin-top: -3px;
                    }
                }
            }

            .pricing-sub-level {
                padding-left: 25px;
                list-style: none;
                
                li {
                    
                    &:before {
                        content: '\f111';
                        font-family: "Font Awesome 5 Free";
                        font-weight: 900;
                        color: $black;
                        width: 5px;
                        height: 5px;
                        font-size: 5px;
                        display: inline-block;
                        vertical-align: middle;
                        line-height: 1;
                        margin-right: 5px;
                        margin-top: -3px;
                    }
                }
            }
        }
    }

    .pricing-compare-plans {
        background-color: $body-bg;

        .list-inline {
            background-color: rgba(222,231,255,.2);
            margin-bottom: 20px;
            padding: 5px;
            border: 1px solid $border-color;
            border-radius: $border-radius;
        }

        .pricing-compare-plans-title {
            font-size: 31px;
            line-height: 1;
            color: $black;
        }

        .pricing-compare-plans-description {
            color: $black;
            line-height: 1;
            font-weight: 400;
        }

        .pricing-compare-plan-label {
            font-size: 18px;
            font-weight: 700;
            line-height: 1;
            color: $black;
        }

        .pricing-compare-plan-price {
            font-size: 16px;
            font-weight: 400;
            color: $black;

            span {
                font-size: 24px;
                font-weight: 700;
            }
        }
    }

    .pricing-plans-details {

        .btn {
            font-size: 16px;
            font-weight: 700;
            color: $black;
            background-color: white;
            border-top: 1px solid $border-color;
            border-radius: 0;
            text-align: left;
            padding: 10px 10px 8px;
        }

        .pricing-plans-details-feature {
            padding: 8px 8px 8px 25px;
            font-size: 16px; 
            font-weight: 400;
            color: $black;

            span {
                border-bottom: 1px dotted $black;
            }

            .pricing-plans-details-title {
                cursor: pointer;
            }

            .pricing-plans-details-feature-description {
                display: none;
                background-color: $light-blue;
                padding: 10px;
                font-size: 12px;
                font-weight: normal;
                bottom: 43px;
                width: 250px;
                left: 30px;
                color: white;
                border-radius: $border-radius;
                height: auto;
                z-index: 2000;

                .tooltip-arrow {
                    margin-left: 10%;
                }
            }

            .pricing-plans-details-title:hover + .pricing-plans-details-feature-description {
                display: block;
            }
        }

        .pricing-plans-details-includes {

            .fa-check {
                color: $green;
            }

            .fa-minus {
                color: $black;
            }
        }
    }
}

// Profile Page
.breadcrumb {
    .breadcrumb-item,
    .breadcrumb-item a {
        color: $primary-color;
        font-weight: bold;
    }

    .breadcrumb-item {

        &.active {
            a {
                text-decoration: none;
            }
        }
    }
}

.profile-like {
    margin-bottom: 15px;

    span {
        display: block;
        text-align: left;
        font-weight: 400;

        &:before {
            content: url('/images/icons/liked.svg');
            width: 20px;
            height: 20px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.stars-container {
    width: 69px;
    height: 15px;
    position: relative;

    .stars {
        width: 69px;
        height: 15px;
        background: url('/images/icons/stars-blue-bg.svg') no-repeat;
        background-size: cover;
        position: absolute;
    }

    .stars-bg {
        height: 15px;
        background-color: $yellow;
        position: absolute;
    }

    .stars-behind {
        width: 69px;
        height: 15px;
        background: $border-color;
    }
}

.profile-stats-box {
    line-height: 1;
    color: $primary-color;
    width: 65%;

    .profile-rating {
        font-size: 18px;
        font-weight: bold;
        margin-right: 15px;
    }

    .profile-total-reviews {
        margin-top: 10px;
        font-weight: 300;
        display: block;
    }
}

.profile-address {
    margin: 20px 0;
    font-size: 12px;
    width: 100%;

    address {
        color: $primary-color;
        display: inline;
        font-weight: normal;
    }

    .proximity {
        color: $light-blue;
        display: inline;
        font-weight: 400;
    }
}

.profile-speciality,
.profile-cp {
    display: inline;
    padding: 3px 16px 2px;
    font-size: 12px;
    line-height: 1;
    border-radius: 30px;
    color: #00053d;
    background-color: rgba(0, 5, 61, 0.1);
    font-weight: 500;
}

.profile-cp {
    background-color: $light-gray;
    color: $accent-primary-color;

    span[aria-hidden="true"] {
        font-size: 16px;
        vertical-align: middle;
        padding-top: 2px;
        cursor: pointer;
    }
}

.profile-phone {
    color: $primary-color;
    text-decoration: none;
    line-height: 1;
    margin: 15px 0 25px;
    display: block;
    font-weight: bold;

    span {
        text-decoration: underline;
    }
}

#profile-overview {

    h1 {
        font-size: 36px;
        color: $primary-color;
        line-height: 1.4;
    }

    h3 {
        width: 100%;
    }

    .button {
        width: 140px;
        margin-right: 15px;
    }

    .button-outline {
        width: 190px;
    }

    .leave-review {
        background-color: $primary-color;
    }

    .leave-review:hover {
        background-color: $light-blue;
    }

    a {
        &.btn {
            color: $secondary-color;
            font-weight: bold;
        }
    }

    .btn {
        transition: none;
    }

    .profile-bio-box {

        h2 {
            font-size: 18px;
            line-height: 1.75;
        }

        .profile-bio {
            color: $accent-primary-color;
        }
    }

    .profile-share {
        text-decoration: none;
        color: $primary-color;
        font-weight: 500;
        padding-left: 0;
        padding-right: 0;
    }

    .profile-image {
        position: absolute;
        right: 0;
        top: 0;
        border-width: 0 0 1px 1px;
        border-style: solid;
        border-color: $border-color;
        border-radius: 0 $border-radius 0 $border-radius;
        box-shadow: 0px 2px 10px $border-color;
    }

    .profile-url-email {

        .btn {
            width: 130px;
            height: 48px;
            line-height: 36px;

            &:hover {
                svg {

                    path {
                        stroke: white;
                    }
                }
            }
        }
    }
}

.share {

    .share-block {
        box-shadow: 0px 2px 10px $border-color;
    }

    .share-overview {

        .profile-image {
            height: 75px;
            border-width: 1px 0 0 1px;
            border-style: solid;
            border-color: $border-color;
            border-radius: $border-radius 0 0 0;
            background: white;
            padding: 5px 0 0;
        }

        .profile-name {
            width: 100%;
            padding: 5px 20px 0;
            background-color: $light-blue;

            h1 {
                color: white;
                font-size: 2rem;
            }
        }
    }

    .review {
        border-top-right-radius: 0;

        .review-header {
            border-top: 1px solid $light-blue;
        }
    }
}

#profile-overview, #profile-mini-block {

    .profile-stats-box {

        .stars-container .stars {
            background: url("/images/icons/stars-white-bg.svg") no-repeat;
        }
    }
}

.profile-navigation {

    .navbar {
        padding: 0;

        .nav-item {

            .nav-link {
                color: $primary-color;
                line-height: 50px;
                font-weight: normal;
                padding-right: 25px;
                padding-left: 25px;
                transition: none;

                &.active {
                    border-bottom: 2px solid $secondary-color;
                }
            }
        }
    }
}

.review-action {
    text-decoration: none;
    color: $primary-color;
    font-weight: 500;
    margin-right: 20px;

    &:last-of-type {
        margin-right: 0;
    }
}

#load-more-button {
    background-color: $primary-color;
}

.profile-title {
    line-height: 1;
    padding: 14px 25px 10px;
    border-radius: $border-radius $border-radius 0 0;
    background: linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
    color: white;
}

#profile-reviews {

    .profile-bar {
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        .progress {
            background: #fcfcfc;
            border: 1px solid $border-color;

            .progress-bar {
                background-color: transparent;
                background: linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
            }
        }

        span {
            font-size: 12px;
            color: $primary-color;
        }
    }

    .profile-review-source {
        width: calc(50% - 10px);
        margin: 0 10px 5px 0;
        background: #fcfcfc;
        border: 1px solid $border-color;
        border-radius: $border-radius;
        padding: 12px 15px 11px;
        color: $primary-color;

        &:nth-of-type(2n) {
            margin-right: 0;
        }

        .source-name {
            font-weight: bold;
            font-style: italic;
        }

        img {
            margin-top: -1px;
        }
    }

    .profile-rating-box {
        border-bottom: 1px solid $border-color;
    }


    #review-search {

        .search-box {

            .form-control {
                border-right-width: 0.3px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                transition: none;
            }
        }

        .review-filter {
            color: $light-blue;
            border: 0.3px solid $light-blue!important;
            background: url('/images/icons/light-blue-arrow.svg') 95% center no-repeat;
            transition: none;
        
            &:focus {
                color: $light-blue;
                border: 0.3px solid $light-blue!important;
            }
        
            &:after { 
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                content: "\f107" !important;
                border: none !important;
                color: $primary-color;
                position: absolute;
                right: 10px;
            }
        }
    }

    .review {
        border-left: 5px solid $secondary-color;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-top: 10px;

        .review-header {
            padding: 1.5rem;

            .reviewer-image img {
                border-radius: 50%;
                margin-right: 15px;
                width: 42px;
                height: 42px;
                background-color: #fcfcfc;
            }

            .reviewer-name {
                font-size: 16px;
                font-weight: bold;
                line-height: 1.25;
            }

            .stars-container {
                .stars {
                    background: url('/images/icons/stars-white-bg.svg') no-repeat;
                }
            }

            .review-source-date {
                text-align: right;

                date {
                    color: $primary-color;
                    opacity: 1;
                    display: block;
                }

                span {
                    color: $primary-color;
                    opacity: 1;
                }

                a {
                    color: $primary-color;
                    text-decoration: none;
                    text-decoration: underline;
                }
            }
        }

        .review-text {
            text-align: justify;
            padding: 0 1.5rem 1.5rem 5.0625rem;
            color: $accent-primary-color;
            font-weight: normal;
            height: auto;
        }

        .review-actions {
            padding: 1.5rem 1.5rem 1.5rem 5.0625rem;

            a {
                font-weight: 500;
                color: $primary-color;
            }
        }
    }

    #leave-review {
        background: $secondary-color url('/images/leave-review-bg.png') center center no-repeat;
        background-size: cover;

        h3 {
            color: white;
            text-align: center;
        }

        form {
            max-width: 540px;
        }

        .form-group,
        .form-check {

            label {
                color: white;
            }
        }

        .form-control {
            color: white;
            background-color: rgba(255, 255, 255, 0.07);
            border-color: rgba(222, 231, 255, 0.5);

            &::placeholder {
                color: white;
            }
        }
    }
}

#profile-info {
    .certifications img {
        margin-right: 40px;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.validation-box {
    
    svg {
        position: absolute;
        right: 10px;
        top: 39%;
        width: 18px;
        height: 18px;
        padding: 3px;
        border-radius: 50%;
        z-index: 99;
    }
}

#profile-locations {

    .locations {
        display: flex;
        flex-wrap: wrap;

        .location {
            border: 1px solid $border-color;
            border-radius: $border-radius;
            width: 24%;
            margin-right: 1.33%;
            text-decoration: none;

            &:last-of-type {
                margin-right: 0;
            }

            .location-name {
                color: $primary-color;
                font-weight: bold;
            }

            .location-info {
                padding: 20px;
            }

            .button {
                width: 100%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
    }
}

#similar-physicians {
    .profile-title {
        border-radius: $border-radius;
    }
}

#profile-mini-block {
    display: none;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 9;

    .section-border {
        border-radius: 0 !important;
    }

    .profile-stats-box {
        max-width: 300px;
    }

    h2 {
        font-size: 36px;
        color: $secondary-color;
        line-height: 1;
    }

    .profile-phone {
        margin: 0;
    }

    .mini-block-overview {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-width: 0;
    }

    .profile-navigation {
        border-top-width: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        .navbar .nav-item .nav-link {
            line-height: 30px;
        }
    }

    .profile-leave-review-phone {

        .button-claim {
            margin-left: 1rem;
        }
    }
}

// SPC Page
#spc-overview {

    .list-unstyled li a {
        color: $body-color;
    }
}

// Login Page
#login {
    .login-type {
        background-color: rgba(222, 231, 255, 0.2);
        padding: 5px;
        margin-bottom: 20px;

        .login-toggle {
            width: 50%;
            font-weight: 300;
            border-radius: $border-radius;
        }

        .btn-check {
            &:checked+.login-toggle {
                background-color: $primary-color;
                color: white;
            }
        }
    }

    .login-background {
        background: url('/images/login-bg.jpg') center center no-repeat;
        background-size: cover;
    }

    a {
        color: $primary-color;
        font-weight: bold;
    }

    /*form {
        a {
            font-size: 12px;
            text-decoration: none;
        }
    }*/
}

// Register Page
#register {

    a {
        color: $accent-primary-color;
        font-weight: bold;
    }

    .register-background {
        background: url('/images/register-bg.jpg') center center no-repeat;
        background-size: cover;
    }
}

// Filters
#filters {
    margin-top: 40px;

    .filters-selected {
        margin-bottom: 25px;
        
        h3 {
            display: inline-block;
            margin-right: 40px;
        }

        a {
            font-size: 12px;
            font-weight: 500;
        }
    }

    .filter {
        flex: 1;

        margin-right: 10px;

        &:last-of-type {
            margin-right: 0;
        }

        .input-group {
            z-index: 99;
        }

        .form-control,
        .input-group-text,
        .dropdown-toggle,
        .input-group-append .btn {
            background-color: #f2f5ff;
        }

        .input-group-prepend {
            margin-right: -2px;
        }

        .form-check-label {
            width: 100%;
        }
    }

}

// Doctors Page 
#advanced-search {

    #queryadvanced {
        border-right: 0.3px solid $border-color;
        border-radius: 0 40px 40px 0;
    }
}

.yellow-star-accent {
    svg {
        path {
            fill: #ffe248;
        }
    }
}

.yellow-star {
    svg {
        path {
            fill: #ffe248;
        }
    }
}

// Popups 
.popup {
    background-color: white;

    .popup-content {
        max-width: 600px;
        padding: 25px 0;
        text-align: center;
        color: white;

        h3 {
            font-size: 1.5rem;
            line-height: 1.5;
            color: $primary-color;

            span {
                color: $light-blue;
            }
        }

        p {
            color: $primary-color;
        }

        .close {
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            border-radius: 25px;
            line-height: 20px;
            color: $primary-color;
            background-color: $light-gray !important;
        }

        .d-flex:not(.popup-btns) {
            flex-wrap: wrap;

            > * {
                width: 49%;
                margin-right: 2%;
            }

            :last-child {
                margin-right: 0;
            }

        }

        .btn {
            height: 40px;
        }

        form {
            text-align: left;

            label {
                color: $primary-color;
            }

            .form-group {
                margin-bottom: 10px;
            }
        }

        .text-danger {
            font-size: 12px;
        }

        .popup-box {
            text-align: left;
            background: #FBFCFF;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            padding: 25px 20px;
            border-color: $light-blue !important;

            button {
                width: 100%;
            }

            p {
                font-size: 12px;
                text-align: center;
            }

            .form-control {
                color: $primary-color;
                background-color: #f2f6ff;
                font-weight: normal;

                &::placeholder {
                    color: $primary-color;
                    opacity: 1;
                }
            }

            .star-rating {
                text-decoration: none;

                svg {
                    stroke: $border-color;
                    fill: #f2f6ff;
                }
            }
        }
    }

    &.popup-npi {

        h3, h4 {
            font-size: 2.15rem;
        }

        h4 {
            font-size: 1.2rem;
        }

        p {
            color: $red;
            font-size: 1rem;
            font-weight: bold;
            font-weight: normal;
        }

    }

    &.popup-email {
        padding: 0 !important;

        .popup-content {
            max-width: 100%;
            padding: 0;

            .popup-email-side {
                flex-direction: column;
            }

            .popup-email-text,
            .popup-email-image {
                width: 100%;
                margin: 0;
            }

            .popup-email-image-wrapper {
                background-color: $new-primary-color;
                border-top-left-radius: $border-radius;

                .popup-email-image {
                    padding: 120px;
                    background-position: center center;
                    background-size: cover;
                    border-top-left-radius: $border-radius;
                    border-bottom-left-radius: $border-radius;
                    border-bottom-right-radius: $border-radius;
                }
            }

            .popup-email-text {
                background-color: $new-primary-color;
                color: white;
                text-align: left;
                padding: 15px;
                border-bottom-left-radius: $border-radius;

                h3,
                p {
                    color: white !important;
                }
            }

            .popup-email-form {
                h4 {
                    text-align: left;
                    font-size: 24px !important;
                    line-height: 1.5 !important;
                }

                .button {
                    height: inherit !important;
                    line-height: inherit !important;
                    background-color: $new-primary-color;
                }

                .form-control {
                    color: $primary-color;
                }
            }
        }

    }

    &.popup-newsletter {
        background: $light-blue url("/images/popup-bg.png") center center no-repeat;
        background-size: cover;

        .popup-content {

            p, h3 {
                color: white;
            }
        }
    }

}

.check-mark-list {
    color: $primary-color;
    list-style: none;
    padding-left: 0;

    li {
        line-height: 2;
        font-weight: normal;

        &:before {
            content: "";
            width: 15px;
            height: 15.5px;
            display: inline-block;
            background-image: url('/images/icons/check-mark.svg');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            margin-right: 7px;
            margin-bottom: -2px;
        }
    }
}

#claim-benefits {
    background: $secondary-color url("/images/claim-benefits-bg.png") center center no-repeat;

    h2 {
        color: white;
        font-size: 28px;
        line-height: 1.2;
    }

    ul {

        li {
            color: white;
        }
    }
}

// 404 Page
.errors\.404 {

    h1 {
        font-size: 8rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    h1, h2 {
        color: $light-blue;
    }
}

// Notification Bar
#notification {
    background-color: #ffe2E9;

    p {
        font-weight: bold;
        color: $primary-color;

        span {
            color: $red;
        }
    }
}

// Page Title
.page-title {
    background: linear-gradient(91.98deg, $secondary-color 2.17%, #49AEFF 98.29%);

    .caption p,
    .title h1,
    .subtitle p {
        letter-spacing: 0.1px;
        font-weight: 600;
        color: white !important;
    }

    .caption {
        font-size: 16px;
        line-height: 1.5;
    }

    .title {
        font-size: 40px;
        line-height: 1;
        letter-spacing: 0.1px;
    }

    .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.5;
    }
}

// FAQ Page
#faq {

    h2 {
        color: $black;
        font-size: 24px;
        line-height: 1.5;
    }

    .intro {
        letter-spacing: 0.1px;

        p {
            color: $black;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 400;

            a {
                font-weight: 500;
                text-decoration: underline;
                color: $black;
            }
        }
    }
}

// Accordion
.accordion {

    ul {
        list-style: none;
        margin: 0 !important;
        padding: 0;

        .accordion-item {
            border: none;
            border-radius: 0;
            border-width: 1px 1px 0 1px;
            border-style: solid;
            border-color: $border-color;

            button {
                border: none;
                outline: none;
                background: white;
                width: 100%;
                letter-spacing: 0.1px;
                font-weight: bold;
                font-size: 16px;
                line-height: 2;
                color: $black;
                padding: 0;
                text-align: left;
                padding: 10px 20px 8px 20px;

                span {
                    width: 100%;
                }
            }

            &:last-of-type {
                
                border-radius: 0 0 10px 10px;
                border-bottom: 1px solid $border-color;

                button,
                .answer {
                    border-radius: 0 0 10px 10px;
                }
            }

            &:first-of-type {
                
                border-radius: 10px 10px 0 0;

                button {
                    border-radius: 10px 10px 0 0;
                }
            }

            .answer {
                color: $black;
                background-color: transparent;
                border-top: 1px solid $border-color;
                font-size: 16px;
                line-height: 1.5;
                letter-spacing: 0.1px;
                font-weight: 400;
                transition: visibility 0s, opacity 0.5s linear;
                padding: 10px 20px 8px 20px;

                br {
                    margin-bottom: 1.5rem;
                }

                a {
                    font-weight: 500;
                    text-decoration: underline;
                    color: $black;
                }
            }
        }
    }
}

// Menu 
.menu {

    ul {
        list-style: none;
        margin: 0 !important;
        padding: 0;

        li {
            border: none;
            border-radius: 0;
            border-bottom: 1px solid $border-color;

            a {
                font-size: 16px;
                line-height: 1.5;
                color: $black;
                text-decoration: none;
                font-weight: bold;
            }

            &:last-of-type {
                border-bottom-width: 0;
            }
        }
    }
}

// Tooltip
.tooltip-content {
    background-color: $light-blue;
    padding: 10px;
    font-size: 12px;
    font-weight: normal;
    bottom: 25px;
    width: 150px;
    left: -75px;
    color: white;
    border-radius: $border-radius;
    height: auto;
    z-index: 99;

    a {
        color: white;
    }

    .tooltip-arrow {
        position: absolute;
        bottom: -8px;
        width: 16px;
        height: 16px;
        z-index: -1;
        box-sizing: border-box;
        background-color: $light-blue;
        transform: rotate(45deg);
        margin-left: 50%;
        left: 0;
    }
}

// Patient Landing Page
.landing-section-title {
    font-family: 'Nunito', sans-serif;
    font-size: 38px;
    line-height: 1.2;
    color: #071e55;
    font-weight: 700;
}

.landing-section-subtitle {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #7c8595;
    font-family: 'Open Sans', sans-serif;
}

#hero {
    background: url("/images/landing-patient-hero.jpg") center center no-repeat;
    background-size: cover;

    h1 {
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        color: white;
        text-shadow: 1px 1px #373737;
        font-weight: 700;
    }

    .subtitle {
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        line-height: 1.5;
        color: white;
        text-shadow: 1px 1px #373737;
        font-weight: 400;
    }

    .hero-section-overlay {
        width: 100%;
        background: #000;
        opacity: 0.39;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .register-from {
        padding: 40px;

        h3 {
            font-family: 'Nunito', sans-serif;
            line-height: 1.2;
            color: #071e55;
            font-size: 22px;
            font-weight: 500;
        }

        p {
            font-size: 17px;
            line-height: 28px;
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            color: #7c8595;
        }

        .form-control {
            width: 100% !important;
            height: 52px !important;
            padding-left: 11% !important;
            padding-right: 5% !important;
            margin-bottom: 15px !important;
            color: #555 !important;
            border: 1px solid #f1f1f1 !important;
            background-color: #f9f9f9 !important;
            font-size: 16px !important;
            font-family: 'Open Sans', sans-serif;
            border-radius: 5px 5px 5px 5px !important;

            &#search {
                background: url('/images/icons/register-form-search-icon.svg') no-repeat 12px 15px;
                background-size: 20px;
            }

            &#email {
                background: url('/images/icons/register-form-email-icon.svg') no-repeat 12px 15px;
                background-size: 20px;
            }
        }

        button {
            background: #e52f37;
            border: none;
            color: #fff;
            letter-spacing: 1px;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: 700;
            font-family: 'Open Sans', sans-serif;
            width: 100%;
            max-width: 535px;
            padding: 16px 0;
            text-transform: uppercase;
            -webkit-border-radius: 3px 3px;
            -moz-border-radius: 3px 3px;
            border-radius: 3px 3px;
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;

            &:hover {
                background: #27b36a;
                transition: all .50s ease-in-out;
                -moz-transition: all .50s ease-in-out;
                -webkit-transition: all .50s ease-in-out;
            }
        }

        .register-form-terms {
            font-size: 12px;
        }
    }
}

#featured-in {
    padding-top: 50px;
    padding-bottom: 50px;

    h5 {
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        color: #071e55;
        font-weight: 700;
    }

    img {
        height: 45px;
        opacity: 0.4;
        filter: alpha(opacity=40);
        cursor: pointer;
        transition: all .50s ease-in-out;
        -moz-transition: all .50s ease-in-out;
        -webkit-transition: all .50s ease-in-out;

        &:hover {
            opacity: 1;
            filter: alpha(opacity=100);
            transition: all .50s ease-in-out;
            -moz-transition: all .50s ease-in-out;
            -webkit-transition: all .50s ease-in-out;
        }
    }
}

#what-we-do {
    background-color: #f1faff;

    .landing-service {

        h4 {
            font-size: 20px;
            font-family: 'Nunito', sans-serif;
            line-height: 1.2;
            color: #071e55;
            font-weight: 700;
        }

        p {
            font-size: 17px;
            line-height: 28px;
            font-weight: 400;
            color: #7c8595;
            font-family: 'Open Sans', sans-serif;
        }

        .landing-service-icon {
            padding: 16px 16px;
            line-height: 46px;
            width: 75px;
            height: 75px;
            border-radius: 20px;
            background-color: #e52f37;

            &.blue {
                background-color: #1e90ff;
            }

            &.green {
                background-color: #27b36a;
            }

            i {
                font-size: 40px;
                text-align: center;
                color: #fff;
            }
        }
    }
}

#discover {

    img {
        box-shadow: 10px 12px 40px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
    }

    h3 {
        font-size: 32px;
        font-family: 'Nunito', sans-serif;
        line-height: 1.2;
        color: #071e55;
        font-weight: 700;
    }
    
    p {
        font-size: 17px;
        line-height: 28px;
        font-weight: 400;
        color: #7c8595;
        margin-bottom: 20px;
        font-family: 'Open Sans', sans-serif;
    }

    ul {
        padding: 0;

        li {
            font-size: 17px;
            line-height: 26px;
            color: #7c8595;
            margin-bottom: 8px;
            font-family: 'Open Sans', sans-serif;
            list-style-type: none;
            font-weight: 400;

            i {
                font-size: 18px;
                line-height: 18px;
                margin-right: 1px;
                color: #e52a3d;
            }
        }
    }

    a {
        font-family: 'Nunito', sans-serif;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        color: #fff;
        background-color: #e52f37;
        border: 1px solid #e52f37;
        box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.27);
        letter-spacing: 1px;
        border-radius: 23px 23px;
        margin: 5px 10px 10px 0;
        padding: 18px 25px;
        display: inline-block;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        text-decoration: none;

        &:hover {
            box-shadow: 0px 4px 10px 0 rgba(0, 0, 0, 0.5);
            transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            background: #27b36a;
            border-color: #27b36a;
        }
    }
}

#facts {
    background: url("/images/landing-facts-bg.jpg") center center no-repeat;
    background-size: cover;
    background-position: top;

    .facts-section-overlay {
        width: 100%;
        background: #e52f37;
        opacity: 0.95;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .fact {

        i {
            font-size: 45px;
            line-height: 60px;
            color: #fff;
        }

        p {
            font-size: 16px;
            line-height: 28px;
            font-weight: 400;
            color: white;
            font-family: 'Open Sans', sans-serif;

            span {
                font-size: 28px;
                line-height: 40px;
                font-weight: 600;
                color: #fff;
            }
        }
    }
}

#testimonials {
    background-color: #f1faff;

    .cards {

        .card {
            background-color: #fff;
            border: 1px solid rgba(0,0,0,.125);
            border-radius: 0;
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem;
            box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
            padding: 15px 25px 15px 35px;
            border-left: 6px solid #27b36a;

            p {
                font-family: 'Open Sans', sans-serif;
                font-size: 20px;
                color: #7c8595;
                font-style: italic;
                font-weight: 400;
            }

            img {
                margin-right: 15px;
            }

            .testimonial-author {
                font-family: 'Nunito', sans-serif;
                font-size: 17px;
                font-weight: 700;
                color: #071e55;

                span {
                    font-size: 17px;
                    color: #27b36a;
                }
            }
        }
    }
}

#features {

    i {
        font-size: 50px;
        line-height: 50px;
        width: 55px;
        height: 55px;
        margin-right: 30px;

        &.green {
            color: #a9d129;
        }

        &.blue {
            color: #50a0d2;
        }

        &.orange {
            color: #fe9c24;
        }
    }

    h4 {
        font-family: 'Nunito', sans-serif;
        font-size: 20px;
        line-height: 1.2;
        color: #071e55;
        font-weight: 700;
    }

    p {
        font-size: 17px;
        line-height: 28px;
        font-weight: 400;
        color: #7c8595;
        font-family: 'Open Sans', sans-serif;
    }
}

#pricing {
    background-color: #f1faff;

    .pricing-box {

        .pricing-list {
            text-align: center;
            background-color: #fff;
            border: 1px solid #dde9fb;
            box-shadow: 0 12px 55px 0 rgba(0,0,0,.05);
            padding: 50px 20px;
            border-radius: 8px 8px;
            transition: all 0.3s ease-in-out;
            list-style: none;
            -webkit-transition: all 0.3s ease-in-out;
            -moz-osx-font-smoothing: grayscale;

            .price-title {
                font-family: 'Open Sans', sans-serif;
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                letter-spacing: 1px;
                color: #292929;
                text-transform: uppercase;
            }

            .price-value {
                font-family: 'Open Sans', sans-serif;
                font-size: 70px;
                line-height: 70px;
                color: #292929;
                font-weight: 500;
            }

            .price-subtitle {
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #6a6a6a;
            }

            .price-tag a {
                color: #e52f37;
                padding: 15px 30px;
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
                font-family: 'Open Sans', sans-serif;
                text-decoration: none;
                text-transform: uppercase;
            }

            .price-text {
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                line-height: 28px;
                font-weight: 400;
                color: #6a6a6a;
            }

            &:hover {
                transform: scale(1.03,1.03);
                -ms-transform: scale(1.03,1.03);
                -webkit-transform: scale(1.03,1.03);
            }

            &.red {
                background-color: #e52f37;

                .price-title,
                .price-value,
                .price-subtitle,
                .price-text {
                    color: white;
                }

                .price-tag a {
                    background-color: white;
                    border-radius: 8px;
                }
            }
        }
    }
}

#faq-section {

    .question {

        h4 {
            font-size: 20px;
            font-family: 'Nunito', sans-serif;
            line-height: 1.2;
            color: #071e55;
            font-weight: 700;
        }

        p {
            font-size: 17px;
            line-height: 28px;
            font-weight: 400;
            color: #7c8595;
            font-family: 'Open Sans', sans-serif;
        }
    }
}

@supports (gap: 1px) {
    #hero {
        background-size: cover;
        background: url("/images/landing-patient-hero.webp") center center no-repeat;
    }

    #facts {
        background-size: cover;
        background: url("/images/landing-facts-bg.webp") center center no-repeat;
        background-position: top;
    }
}

// Responsiveness
@media (min-width: 768px) {
    .collapse.dont-collapse-md {
        display: block;
        height: auto !important;
        visibility: visible;
    }
}

@media (max-width: 1023px) {
    #sidebar {
        display: block;
        margin-left: -230px;

        .navbar-brand {

            img {
                width: 113.62px;
                height: 31.28px;
            }
        }

        &.active {
            margin-left: 0;

            +.mobile-overlay {
                display: block !important;
                background-color: rgba(242, 247, 255, 0.7);
                filter: blur(25px);
                position: fixed;
                z-index: 998;
            }
        }

        .menu-close {
            color: $primary-color;
            position: absolute;
            right: 10px;
            top: 25px;
        }

        ul {
            li {
                border-bottom: 1px solid $border-color;
                padding: 10px 20px;

                &:first-of-type {
                    border-top: 1px solid $border-color;
                }
            }
        }
    }

    .profile-navigation {

        .section-border {
            overflow-x: auto;
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .navbar-nav {
            flex-direction: unset !important;
        }
    }
}

@media screen and (min-width: 1199px) and (max-width: 1279px) {
    .popup.popup-npi {
        width: 75% !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    #profile-overview {

        .profile-speciality {
            margin-bottom: 20px !important;
        }
    }

    #stats .stats-box p {
        font-size: 14px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {

    .section-spacing {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }

    .spacing-top {
        margin-top: 2.5rem;
    }

    .spacing-bottom {
        margin-bottom: 2.5rem;
    }

    h1, .h1, h2, .h2 {
        font-size: 2rem !important;
    }

    header .mobile-login-btn a {
        padding: 0.75rem;
        font-weight: 700;
        text-decoration: none;
        color: #222d74;
        line-height: 48px;
    }

    // Cover
    #cover {
        min-height: 500px;
    }
    
    // Stats
    #stats {
        padding-bottom: 2.5rem;

        .stats-box {
            padding: 10px 5px;
            margin-top: 50px;

            h2 {
                font-size: 1.5rem;
                line-height: 2rem;
            }

            p {
                font-size: 11px;
            }
        }
    }

    // Doctors Block
    .doctors-block {
        width: 49%;
        margin-right: 2%;

        &:nth-of-type(2n) {
            margin-right: 0;
        }
    }

    // Call to Action 
    .call-to-action {
        padding: 0.5rem;
        min-height: 250px;
        margin-bottom: 2.5rem;

        h2 {
            line-height: 1.3;
        }
    }

    // Contact Us 
    #contact-map {
        margin-bottom: 2.5rem;
    }

    // Doctors
    .filters-collapse {
        display: none !important;
    }

    // Profile page
    #profile-overview {

        h1 {
            line-height: 1;
        }

        .section-padding {
            padding: 30px 25px 60px;
        }

        .profile-stats-box {
            width: 100%;
        }

        .profile-buttons {
            width: 100%;
            position: absolute;
            bottom: 30px;
            left: 25px;
        }

        .profile-share {
            position: absolute;
            width: 60px;
            right: 25px;
        }

        .profile-speciality {
            margin-bottom: 20px !important;
        }
    }

    #profile-reviews {

        .profile-review-source {
            width: 100%;
            margin-right: 0;
        }
    }

    #profile-mini-block {
        .col-md-5,
        .col-md-7 {
            width: 100% !important;
        }

        .col-md-5 {
            margin-bottom: 15px;
        }

        .mini-block-overview {
            padding-bottom: 15px;
        }
    }

    .profile-review-box  {

        .col-md-5 {
            flex: 2;
        }

        .col-md-2 {
            flex: 1;
        }
        
        .col-md-1 {
            display: none;
        }
    }

    #profile-reviews #review-search .review-filter {
        background: url("/images/icons/light-blue-arrow.svg") 95% center no-repeat;
    }

    // Popups 
    .position-fixed .popup {
        width: 85% !important;
    }

    #notification {
        .col-md {
            display: none;
        }

        .col-md-7 {
            width: 70% !important;
        }

        .col-md-2 {
            width: 30%;
        }
    }
}

@media (max-width: 767px) {
    h1, h2 {
        font-size: 24px !important;
        line-height: 1.5 !important;
    }

    #faq {

        h2 {
            font-size: 18px !important;
        }
    }

    .section-spacing {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .spacing-top {
        margin-top: 48px;
    }
    
    .spacing-bottom {
        margin-bottom: 48px;
    }

    .section-padding {
        padding: 12.5px;
    }

    .navbar-toggler {
        color: transparent !important;
        border-color: transparent !important;
    }

    header {
        position: relative;
        z-index: 9;
        height: 70px;

        .navbar-brand {
            width: auto;
            margin: 0;

            img {
                width: 113.62px !important;
                height: 31.28px !important;
            }
        }

        .mobile-login-btn a {
            padding: 0.75rem;
            font-weight: 700;
            text-decoration: none;
            color: $primary-color;
            line-height: 48px;
        }
    }

    .navbar-collapse {
        position: fixed;
        top: 0;
        left: 0;
        width: 70%;
        height: 100%;
        z-index: 9999;
        background-color: white;

        &.collapsing {
            height: auto;
            -webkit-transition: left 0.3s ease;
            -o-transition: left 0.3s ease;
            -moz-transition: left 0.3s ease;
            transition: left 0.3s ease;
            left: -100%;
        }

        &.show {
            left: 0;
            -webkit-transition: left 0.1s ease-in;
            -o-transition: left 0.1s ease-in;
            -moz-transition: left 0.1s ease-in;
            transition: left 0.1s ease-in;
        }

        .slide-menu-header {
            padding: 27px;

            .menu-close {
                color: $primary-color;
            }
        }

        .nav-item {
            border-bottom: 1px solid $border-color;

            &:first-of-type {
                border-top: 1px solid $border-color;
            }

            &:last-of-type {
                border: none;
            }
        }

        .nav-link {
            &.button {
                max-width: 120px;
                margin: 10px 27px;
            }
        }
    }

    #cover {
        min-height: 390px;
        align-items: start !important;

        #home-search {
            padding: 60px 0;
            text-align: center;

            p {
                line-height: 1.5 !important;
            }

            h1 {
                margin: 20px 0 !important;
            }
        }
    }

    #how-it-works {
        #graphic {
            margin-bottom: 48px;

            #graphic-rating {
                top: 8%;
                left: 5%;
            }

            #graphic-block {
                bottom: 5%;
                right: 0;
            }
        }
    }

    #stats {
        padding-bottom: 48px;

        .stats-box {
            padding: 15px 15px;
            margin-top: 55px;
            min-height: 113px;
        }
    }

    #top-providers {
        .container {
            padding: 0;
        }
        
        .view-all-doctors {
            text-align: center;
        }
    }

    .doctors-block {
        margin-bottom: 1.5rem;
        width: 100%;
        margin-right: 0;
    }


    .blog-wrap {
        display: block !important;

        .blog-block {
            display: block !important;
            margin-bottom: 1.5rem !important;
            width: 100% !important;
            margin-right: 0 !important;

            h3 {
                font-size: 18px !important;
                line-height: 1.5 !important;
            }

            .blog-image {
                border-radius: $border-radius $border-radius 0 0;
                filter: drop-shadow(0px 16px 40px rgba(0, 0, 0, 0.15));
            }

            &:first-of-type {
                display: block;
                padding-bottom: 36px !important;

                .blog-image {
                    border-radius: $border-radius $border-radius 0 0 !important;
                    height: 220px !important;
                    width: 100% !important;
                    filter: drop-shadow(0px 16px 40px rgba(0, 0, 0, 0.15));
                }

                .article-desc {
                    width: 100% !important;
                    padding: 25px 20px !important;
                    position: initial !important;

                    .button {
                        left: 20px !important;
                        bottom: 20px !important;
                    }
                }
            }

            &:last-of-type {
                 margin-bottom: 0 !important;
            }
        }
    }

    .call-to-action {
        padding: 1rem;
        margin-bottom: 48px;
        min-height: 440px;
        background: url('/images/cta-bg-mobile.png') center center no-repeat, linear-gradient(45deg, $light-blue 0%, $dark-blue 100%);
        background-size: cover;
    }

    footer {
        p {
            word-break: break-all;
        }

        .col-md-4 {

            img {
                display: block;
            }

            p {
                display: inline;

                &:last-of-type {
                    display: block;
                }
            }
        }
    }

    .filters-collapse[aria-expanded="true"] {
        border-bottom-width: 0 !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    
        &:after {
            transform: rotate(0.5turn);
        }
    }
    
    #filtersWrap {
        border-style: solid;
        border-color: $border-color;
        border-width: 0 0.3px 0.3px 0.3px;
        border-radius: 0 0 $border-radius $border-radius;
    }

    #filters {
        margin-top:  0 !important;

        .filters-selected {
            h3 {
                display: none !important;
            }
        }

        &.section-border {
            border: none;
        }

        .d-flex {
            display: block !important;

            .filter {
                width: 100%;
                margin-right: 0 !important;
                margin-bottom: 1rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                .search-results {
                    position: relative;
                    margin-top: -2.5rem;
                    margin-bottom: 3rem;
                }

                .dropdown-menu.show {
                    position: relative !important;
                    margin-top: -46px !important;
                    margin-bottom: 3rem !important;
                }
            }
        }
    }
    
    #contact-map {
        margin-bottom: 48px;
    }

    #login {
        margin: 48px 0;

        .col-md {

            div {
                height: auto !important;
                align-items: start !important;

                div {
                    width: 100% !important;
                    margin-right: 0 !important;
                }
            }
        }

        .login-type {

            .login-toggle {

                &:first-of-type {
                    width: 70%;
                }
            }
        }
    }

    #register {
        margin: 48px 0;
        
        .col-md {

            div {
                height: auto !important;
                align-items: start !important;

                div {
                    width: 100% !important;
                    margin-right: 0 !important;
                }
            }
        }

    }

    .breadcrumb-container {

        nav {
            -ms-overflow-style: none;
            -webkit-overflow-scrolling: touch;
            overflow-x: auto !important;
            scrollbar-width: none;
            white-space: nowrap;

            .breadcrumb {
                flex-wrap: initial;

                .breadcrumb-item a {
                    height: 48px;
                    font-size: 14px;
                }

                .breadcrumb-item a,
                .breadcrumb-item {
                    line-height: 48px;
                }

                .breadcrumb-item {

                    &::before {
                        float: none !important;
                    }
                }
            }
        }
    }

    #profile-overview {

        h1 {
            width: calc(100% - 95px);
        }

        .profile-phone {
            margin: 15px 0;
        }

        .profile-specialties {
            display: block;
            height: 48px;
            margin: 0 0 15px 0 !important;
            line-height: 48px;
        }

        .profile-image {
            top: 75px;
            right: 12.5px;
            border: 1px solid $border-color;
            border-radius: $border-radius;
            width: 57px;
        }

        .profile-bio-box {
            flex-direction: column-reverse;
        }

        .profile-stats-box {
            width: 100%;
            padding: 12px 0 10px;
        }

        .profile-buttons {
            display: block !important;
            width: 100%;

            .btn {
                width: 100% !important;
                margin: 0 0 15px 0;
                height: 48px;
                line-height: 36px;
            }
        }

        .profile-address {
            margin: 0 0 15px;
        }

        .profile-url-email {
            margin-bottom: 15px;

            .btn {
                flex: 1;

                &:last-of-type {
                    margin-right: 0 !important;
                }
            }
        }
    }

    #profile-reviews {

        .profile-review-sources {
            margin-top: 20px;

            .profile-review-source {
                width: 100%;
                margin-right: 0;
            }
        }

        .review {

            .review-text {
                padding-left: 0;
                font-size: 13px;
                color: $accent-primary-color;
            }

            .reviewer-name {
                font-size: 14px;
            }

            .review-source-date {
                font-size: 12px;
            }

            .review-actions {
                padding-left: 0;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    #profile-locations {

        .locations {

            .location {
                width: 100%;
                margin-right: 0;
                margin-bottom: 1rem;

                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }

    #profile-mini-block {

        h2 {
            width: calc(100% - 95px);
        }

        .col-md-7 > .d-flex {
            display: block !important;
        }

        .profile-stats-box {
            margin-bottom: 10px;
        }

        .profile-stats-box,
        .btn {
            width: 100% !important;
            max-width: 100% !important;
        }

        .profile-leave-review-phone {
            flex-direction: row-reverse;
            flex-wrap: wrap;

            > div, .button-claim {
                width: calc(50% - 0.5rem) !important;
                margin: 0 !important;
            }

            > div > .button, .button-claim {
                font-size: 13px !important;
            }

            .button-claim {
                margin-right: 1rem !important;
            }
        }
    }

    #review-search {

        .form-group,
        .input-group {
            margin-bottom: 15px;
        }
    }

    #profile-reviews {

        .review {
            .review-header,
            .review-actions {
                padding: 10px;
            }

            .review-header {

                .reviewer-name {
                    font-size: 14px;
                }

                .reviewer-image img {
                    margin-right: 10px;
                }
            }

            .review-text {
                padding: 0 10px 0;
            }
        }
    }

    .profile-navigation {

        .section-border {
            overflow-x: auto;
            white-space: nowrap;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -webkit-overflow-scrolling: touch;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .navbar-nav {
            flex-direction: unset !important;
        }
    }

    .popup {
        width: calc(100% - 1.5rem) !important;

        .popup-content  {

            padding: 0 !important;
            
            h3 {
                font-size: 18px;;
            }

            input.form-control {
                height: 35px !important;
            }

            textarea.form-control {
                height: 60px !important;
            }

            .btn {
                height: 35px !important;

                span {
                    display: none;
                }
            }

        }

        &.popup-npi {

            .popup-box {
                padding: 25px 20px;

                &.active {
                    margin-top: 20px;
                }
            }
        }

        .popup-email-side {
            display: none !important;
        }
    }

    #claim-section {

        min-height: 230px;
        background: $light-blue url('/images/claim-profile-mobile-bg.svg') center no-repeat;
        flex-direction: column-reverse;
        border-radius: $border-radius $border-radius 0 0;
        background-size: 115%;

        .section-padding {
            padding: 25px;
        }
    
        h3 {
            font-size: 24px;
            text-align: center;
        }
    
        .btn {
            width: 100%;
        }
    
        #claim-section-image {
            background: $light-blue url('/images/claim-section-image-mobile.svg') no-repeat;
            background-size: cover;
            min-height: 312px;
            border-radius: $border-radius $border-radius 0 0;
        }
    }
    
}

@media (max-width: 480px) {
    #profile-mini-block {
        .mini-block-overview {
            display: none;
        }
    }

    .profile-loading {
        height: 5300px !important;
    }

    #notification {
        p {
            font-weight: bold;
            color: #222d74;
            font-size: 12px;
            margin-bottom: 5px !important;
        }
    }

    #profile-overview .profile-bio-box {
        overflow-x: hidden !important;
        overflow-y: auto !important;
        width: 100% !important;
        height: auto !important;
        position: relative !important;
    }

    .profile-navigation {
        width: 100% !important;
        height: auto !important;
        position: relative !important;
    }

    #blog-single-author-box {

        > .d-flex {
            display: block !important;
        }

        .blog-single-author-box-image,
        .blog-single-author-box-description {
            width: 100% !important;
        }
    }

        // Pricing
        .pricing {

            h2,
            .pricing-page-subtitle {
                text-align: left !important;
            }

            .pricing-page-subtitle {
                font-size: 16px;
                margin-bottom: 1.2rem !important;
            }
    
            #pricing-organization + .pricing-plans .pricing-box .pricing-plan-price {
                font-size: 24px;
            }
    
            .pricing-compare-plans {

                &.sticky-top {
                    top: -76px;
                  }
                
            }

            .pricing-plans-details {
                
                .w-50 {
                    width: 75% !important;
                }
            }
    
        }
}